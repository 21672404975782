import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import ManageItemsForm from "./manage-items-form";
import {formatDate, formatDateAndTime} from "../../resources/constants";

function ManageItems(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "Title", "Price", "Quantity", "StartDate", "EndDate", "Status", "Action"];
    const [formData, setFormData] = useState({
        ItemID: "",
        Title: "",
        Description: "",
        Price: "",
        Quantity: "",
        StartDate: "",
        EndDate: "",
        Status: "",
        ImagePath: "",
        ImageName: "",
        ImageDisplay: "",
        InsertedBy: `${props.loginData[0]?.staff_id}`,
    })
    const [imageList, setImageList] = useState([]);

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}auction/get-auction-items`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Title}</td>
                        <td className="text-xs font-weight-bold">{item.Price}</td>
                        <td className="text-xs font-weight-bold">{item.Quantity}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.StartDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.EndDate, 'date')}</td>
                        <td className={`text-xs font-weight-bold ${item.Status?.toString() ==="1" ? "text-success" : "text-danger"}`}>{item.Status?.toString() ==="1" ? "Active" : "In-Active"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       ItemID: item.ItemID,
                                       Title: item.Title,
                                       Description: item.Description,
                                       Price: item.Price,
                                       Quantity: item.Quantity,
                                       StartDate: item.StartDate,
                                       EndDate: item.EndDate,
                                       Status: item.Status,
                                       InsertedBy: item.InsertedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter item name", "error");
            return false;
        }

        if (formData.Price.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter item price", "error");
            return false;
        }

        if (formData.Quantity.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter item quantity", "error");
            return false;
        }

        if (formData.StartDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select start date", "error");
            return false;
        }

        if (formData.EndDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select end date", "error");
            return false;
        }

        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select item status", "error");
            return false;
        }

        if (formData.ItemID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}auction/add-auction-item`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (imageList.length > 0) {
                            const formData = new FormData();
                            imageList.map(item => {
                                formData.append('photo', item.ImagePath);
                                formData.append('entry_id', result.data.entry_id)
                                formData.append('InsertedBy', sendData.InsertedBy)
                            })
                            axios.patch(`${serverLink}auction/multiplePhoto`, formData)
                                .then(result => {
                                    toast.success("Item Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    setFormData({
                                        ...formData,
                                        ItemID: "",
                                        Title: "",
                                        Description: "",
                                        Price: "",
                                        Quantity: "",
                                        StartDate: "",
                                        EndDate: "",
                                        Status: "",
                                    });
                                    setImageList([])
                                })
                                .catch(err => {
                                    toast.error("Something went wrong. Please try again!");
                                    setIsFormLoading(false)
                                });
                        }else {
                            toast.success("Item Added Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("closeModal").click();
                            setFormData({
                                ...formData,
                                ItemID: "",
                                Title: "",
                                Description: "",
                                Price: "",
                                Quantity: "",
                                StartDate: "",
                                EndDate: "",
                                Status: "",
                            });
                        }
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                StartDate: formatDate(formData.StartDate),
                EndDate: formatDate(formData.EndDate)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}auction/update-auction-item`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Item Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            ItemID: "",
                            Title: "",
                            Description: "",
                            Price: "",
                            Quantity: "",
                            StartDate: "",
                            EndDate: "",
                            Status: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    const onMultipleImageChange = e => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            files.forEach((file, index) => {
                const reader = new FileReader();
                if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                } else {
                    toast.error("Only .png, .jpg and .jpeg format allowed!")
                    return false;
                }
                // if (file.size > 2000000) {
                //     toast.error("max file size is 2mb",)
                //     return false;
                // }

                reader.onload = function () {
                    let data = {
                        ImagePath: file,
                        ImageName: file.name,
                        ImageDisplay: reader.result,
                        ImageSize: file.size,
                    };
                    setImageList(prevState => [...prevState, data])
                }
                reader.readAsDataURL(file)
            })

        }
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Auction Manage Item
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               ItemID: "",
                                               Title: "",
                                               Description: "",
                                               Price: "",
                                               Quantity: "",
                                               StartDate: "",
                                               EndDate: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Item
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Auction Item(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Auction Item(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageItemsForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    descriptionHandler={descriptionHandler}
                    onMultipleImageChange={onMultipleImageChange}
                    imageList={imageList}
                    setImageList={setImageList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageItems);
