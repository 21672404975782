import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";

import Skeleton from "../common/skeleton/skeleton";
import { MaleAvatar, WomanAvatar} from "../../resources/constants";
import {Link} from "react-router-dom";
import {currencyConverter, formatDate, formatDateAndTime} from "../../resources/constants";

function Dashboard(props) {

    const user = props.loginData[0];
    const [IsLoading, setIsLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}auction/display-auction-items`)
            .then((result) => {
                if (result.data.data.length > 0) {
                    setItemsList(result.data.data);
                    setImageList(result.data.image);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }



    return (
        IsLoading ?

            <Skeleton/>
            :
        <>
            <div className="container-xl">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-deck row-cards">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <span className="avatar avatar-lg img-thumbnail rounded" style={{backgroundImage: user?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`}}/>
                                    </div>
                                    <div className="col">
                                        <h2 className="fw-bold">{user?.staff_name} </h2>
                                        <div className="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="icon me-2 text-muted" width="24" height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path
                                                    d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"/>
                                                <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"/>
                                                <path d="M12 12l0 .01"/>
                                                <path d="M3 13a20 20 0 0 0 18 0"/>
                                            </svg>
                                            <span className="text-reset">{user?.designation} </span>
                                        </div>
                                        <div className="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="icon me-2 text-muted" width="24" height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M5 12l-2 0l9 -9l9 9l-2 0"/>
                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/>
                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/>
                                            </svg>
                                            <span className="text-reset">{user?.Department} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Auction Item(s)</h3>
                                    <div className="row row-cards">
                                        {
                                            itemsList.length > 0 ? itemsList.map((item, index)=>{
                                                let image_to_display = '';
                                                let distinctImage =  imageList.filter(e=>e.ItemID.toString() === item.ItemID.toString());
                                                if(distinctImage.length > 0 ){
                                                    image_to_display = distinctImage[0].ImagePath
                                                }
                                                return(
                                                    <div key={index} className="col-sm-6 col-lg-4">
                                                        <div className="card card-sm">
                                                            <Link to={`/item/details/${btoa(item.ItemID)}`} className="d-block"><img src={`${serverLink}public/uploads/auction/${image_to_display}`} className="card-img-top"/></Link>
                                                            <div className="card-body">
                                                                <div className="">
                                                                    <div><Link to={`/item/details/${btoa(item.ItemID)}`}><h3 className="text-primary font-weight-bold">{item.Title}</h3></Link></div>
                                                                    <hr style={{margin: '10px'}}/>
                                                                    <div className="d-flex  justify-content-between align-content-between">
                                                                        <div>Duration: </div>
                                                                        <div className="text-secondary">{formatDateAndTime(item.StartDate, 'date')} - {formatDateAndTime(item.EndDate, 'date')}</div>
                                                                    </div>
                                                                    <div className="d-flex  justify-content-between align-content-between">
                                                                        <div>Price: </div>
                                                                        <div className="text-secondary">{currencyConverter(item.Price)}</div>
                                                                    </div>
                                                                    <div className="d-flex  justify-content-between align-content-between">
                                                                        <div>Quantity: </div>
                                                                        <div className="text-secondary">{item.Quantity} available</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className="col-md-12 text-center alert alert-info alert-important">
                                                <h1 className="text-center">No Available Item</h1>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Dashboard);
