import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {paymentAPIKey, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import {currencyConverter, formatDate, formatDateAndTime, generate_token} from "../../resources/constants";
import {Link, useParams} from "react-router-dom";
import {usePaystackPayment} from "react-paystack";
import {toast} from "react-toastify";
import {showAlert} from "../common/sweetalert/sweetalert";

function ItemDetails(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const {slug} = useParams();
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "Title", "Price", "Quantity", "StartDate", "EndDate", "Status", "Action"];
    const [formData, setFormData] = useState({
        Price: "",
        ItemID: "",
        EmailAddress: `${props.loginData[0]?.BazeEmail}`,
        InsertedBy: `${props.loginData[0]?.staff_id}`,
    })
    const [imageList, setImageList] = useState([]);

    const config = {
        reference: "TRANX"+generate_token(10),
        email: `${props.loginData[0]?.BazeEmail}`,
        amount:  parseFloat(formData.Price)  * 100,
        publicKey: paymentAPIKey,
        currency: 'NGN',
    };

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}auction/auction-details/${atob(slug)}`)
            .then((result) => {
                if (result.data.data.length > 0) {
                    setItemsList(result.data.data);
                    setFormData({
                        ...formData,
                        Price: result.data.data[0].Price,
                        ItemID: result.data.data[0].ItemID
                    })
                    setImageList(result.data.image);
                }else{
                    window.location.href = '/items'
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onPostPayment = async (ref) => {
        try {
            setIsFormLoading(true)
            const sendData = {
                ...formData,
                PaymentReference: "TRANX"+generate_token(10),
                Amount: formData.Price,
                AuctionItemID: formData.ItemID,
                StaffID: formData.InsertedBy,
            }

            toast.info("please wait...");
            await axios.post(`${serverLink}auction/post-payment`, sendData).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success("Request Submitted Successfully");
                    setIsFormLoading(false);
                    setTimeout(()=>{
                        window.location.href = "/payments"
                    }, 2000)

                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong posting payment. Please try again!");
                }
            }).catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }catch (e) {
            showAlert(
                "PAYMENT ERROR",
                "Ooops!, something went wrong posting payment. please try again!",
                "error"
            );
        }
    }

    const onSuccess = async (reference) => {
        console.log(reference)
        try {
            await onPostPayment(reference)
        } catch (error) {
            console.error("Error in onPostPayment:", error)
        }
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment =  usePaystackPayment(config);

    const paymentInitialize = async  () => {
        if (formData.Price < 1) {
            toast.error("Please enter amount to fund wallet");
            return false;
        }

        await axios.get(`${serverLink}auction/check-requirement/${atob(slug)}/${formData.InsertedBy}`)
            .then(async (result) => {
                if (result.data.length > 0) {
                    toast.error("You cannot apply more than once");
                }else{
                    await onPostPayment()
                    // await initializePayment(onSuccess, onClose)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Item Details
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h2 className="card-title text-primary"><b>{itemsList[0].Title}</b></h2>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="card card-sm">
                                                            <div className="card-body">
                                                                <div className="row row-cards">
                                                                    <img src={`${serverLink}public/uploads/auction/${imageList.length > 0 ? imageList[0].ImagePath : 'placeholder.png'}`} className="card-img-top"/>
                                                                    {
                                                                        imageList.length > 0 && imageList.map((item, index)=>{
                                                                            return(
                                                                                <div key={index} className="col-sm-6 col-lg-4">
                                                                                    <div className="card card-sm">
                                                                                        <div className="card-body">
                                                                                            <img src={`${serverLink}public/uploads/auction/${item.ImagePath}`} style={{height: '100px', width: '100%'}} className="card-img-top"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="card card-sm">
                                                            <div className="card-body">
                                                                <h4><i>Item Details</i></h4>
                                                                <hr style={{marginTop: '10px', marginBottom: '5px'}} />
                                                                <table className="table" cellPadding={10}>
                                                                    <tr>
                                                                        <th>Price:</th>
                                                                        <td><b>{currencyConverter(itemsList[0].Price)}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Quantity:</th>
                                                                        <td>{itemsList[0].Quantity} available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th width={300}>Duration:</th>
                                                                        <td>{formatDateAndTime(itemsList[0].StartDate, 'date')} => {formatDateAndTime(itemsList[0].EndDate, 'date')}</td>
                                                                    </tr>
                                                                </table>
                                                                <hr style={{marginTop: '10px', marginBottom: '15px'}} />
                                                                <div className="col-md-12 m-2">
                                                                    <h4><b>Description</b></h4>
                                                                    <hr style={{marginTop: '10px'}} />
                                                                    <p style={{padding: '0px'}} dangerouslySetInnerHTML={{__html: itemsList[0].Description}} />
                                                                </div>
                                                                <hr style={{marginTop: '10px'}} />
                                                                <div className="col-md-12 mt-1">
                                                                    {
                                                                        itemsList[0].Quantity > 0 ?
                                                                            <button className="btn btn-primary form-control"  onClick={paymentInitialize}> Submit Application </button>
                                                                            :
                                                                            <div className="alert alert-important alert-danger text-center"><h3>No available item</h3></div>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ItemDetails);
