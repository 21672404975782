import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import {currencyConverter,  formatDateAndTime} from "../../resources/constants";

function PaidNotCollected(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "StaffID", "Staff Name", "Item Name", "Price", "Payment Reference", "PaymentDate", "Status"];

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}auction/get-paid-not-collect-items`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StaffID}</td>
                        <td className="text-xs font-weight-bold text-capitalize">{item.StaffName}</td>
                        <td className="text-xs font-weight-bold">{item.ItemName}</td>
                        <td className="text-xs font-weight-bold">{currencyConverter(item.Amount)}</td>
                        <td className="text-xs font-weight-bold">{item.PaymentReference}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.PaymentDate, 'date')}</td>
                        <td className={`text-xs font-weight-bold ${item.Status?.toString() ==="1" ? "text-success" : "text-danger"}`}>{item.Status?.toString() ==="1" ? "Collected" : "Not Collected"}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Paid Not Collected Payment Report
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Paid Not Collected Payment Report</h3>
                                                <DataTable header={header} body={showTable()} title="Paid Not Collected Report" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(PaidNotCollected);
