import React from "react";
import JoditEditor from "jodit-react";
import {formatDate} from "../../resources/constants";
export default function ManageItemsForm(props) {
    const editorConfig = {
        readonly: false,
        height: 200
    };
    const onDelete = (e, item) => {
        let files = props.imageList
        let filter = files.filter(e => e.ImageName !== item.ImageName);
        props.setImageList(filter)
    }

    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Auction Items Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Item Name</label>
                                <input
                                    name="Title"
                                    className="form-control"
                                    id="Title"
                                    value={props.formData.Title}
                                    onChange={props.onEdit}
                                    placeholder="Title"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Price</label>
                                <input
                                    type="number"
                                    name="Price"
                                    className="form-control"
                                    id="Price"
                                    value={props.formData.Price}
                                    onChange={props.onEdit}
                                    placeholder="Item Price"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Quantity</label>
                                <input
                                    type="number"
                                    name="Quantity"
                                    className="form-control"
                                    id="Quantity"
                                    value={props.formData.Quantity}
                                    onChange={props.onEdit}
                                    placeholder="Quantity"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    name="StartDate"
                                    className="form-control"
                                    id="StartDate"
                                    value={formatDate(props.formData.StartDate)}
                                    onChange={props.onEdit}
                                    placeholder="StartDate"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">End Date</label>
                                <input
                                    type="date"
                                    name="EndDate"
                                    className="form-control"
                                    id="EndDate"
                                    value={formatDate(props.formData.EndDate)}
                                    onChange={props.onEdit}
                                    placeholder="EndDate"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Status</label>
                                <select className="form-control" name="Status" id="Status" value={props.formData.Status} onChange={props.onEdit}>
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">In-Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-12 mb-3" style={{border: '1px solid #eeeeee', padding: '15px'}}>
                            <div className='divider divider-start'>
                                <div className='divider-text'>Add Multiple Images</div>
                            </div>
                            <input type="file" id="MultipleImages" name="MultipleImages" accept={"image/*"}
                                   className="form-control" onChange={props.onMultipleImageChange} multiple/>
                        </div>
                        <div>
                            <>
                                {

                                    props.imageList.length > 0 ?
                                        <div className='divider'>
                                            <div className='divider-text'>Selected Images</div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            {
                                props.imageList.length > 0 && props.imageList.map((item, index) => {
                                    const size = (parseInt(item.ImageSize) / (1024 * 1024)).toFixed(2);
                                    return (
                                        <div key={index} className="form-group mb-1">
                                            <div className='border rounded p-2'>
                                                <div className='d-flex flex-column flex-md-row'>
                                                    <img
                                                        className='rounded me-2 mb-1 mb-md-0'
                                                        src={item.ImageDisplay}
                                                        alt='featured img'
                                                        width='60'
                                                        height='60'
                                                    />
                                                    <div className="w-100">
                                                        <small className='text-muted'>{item.ImageName}</small>

                                                        <p className='my-50'>
                                                            <a href='/file/public' onClick={e => e.preventDefault()}>
                                                                {`${size} MB`}
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className="float-right">
                                                        <button type="button" className='btn btn-danger btn-sm float-end'
                                                                onClick={(e) => onDelete(e, item)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M4 7l16 0" />
                                                                <path d="M10 11l0 6" />
                                                                <path d="M14 11l0 6" />
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="mb-3 col-md-12 form-group">
                            <label className="form-label">Description </label>
                            <JoditEditor
                                value={props.formData.Description}
                                config={editorConfig}
                                onBlur={props.descriptionHandler}
                                onChange={(newContent) => {}}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}