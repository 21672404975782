import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {MaleAvatar, MenuIcon, WomanAvatar} from "../../../resources/constants";
import {setLoginDetails, setPermissionDetails} from "../../../actions/actions";

function Header(props) {
    const [menuList, setMenuList] = useState([])
    const [subMenuList, setSubMenuList] = useState([])
    const [permissionList, setPermissionList] = useState(props.permissionData)
    const user = props.loginData[0];
    const avatar = user?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`;
    const username = user?.staff_name;
    const IsAdmin = user?.IsAdmin;

    useEffect(() => {
        // let main_menu_array ; let sub_menu_name = [];  const permission = props.permissionData;
        // if (props.permissionData.length > 0){
        //
        //     //Main Menu
        //     main_menu_array = permission.map(e=>e.MenuName);
        //     const main_menu = [...new Set(main_menu_array)]
        //     setMenuList(main_menu)
        //
        //     //Sub Menu
        //     let sub_row = [];
        //     main_menu.map((e,i)=>{
        //         sub_menu_name = [...new Set(permission.filter(x=>x.MenuName === e).map(item=>item.SubMenuName))];
        //         sub_row.push({
        //             MenuName: e,
        //             SubMenuName: sub_menu_name,
        //         })
        //     })
        //     setSubMenuList(prevState => [prevState, ...sub_row])
        // }

    }, [""])

    const dismissClick = () => {
        document.getElementById("toggle-nav").click();
    }

    const signOut = () => {
        props.setOnLoginDetails([]);
        props.setOnPermissionDetails([]);
        window.location.href = "/"
    };

    return (
        <>
            <header className="navbar navbar-expand-md navbar-light d-print-none">
                <div className="container-xl">
                    <button className="navbar-toggler" id="toggle-nav" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-menu">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                        <a href=".">
                            <img src={require('../../../images/logo.png')} width="150" height="150" alt="Baze Logo"
                                 className="navbar-brand-image"/>

                        </a> <span style={{marginLeft: '10px'}}> Baze University</span>
                    </h1>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="nav-item d-none d-md-flex me-3"/>
                        <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
                            </svg>
                        </a>
                        <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="4"/>
                                <path
                                    d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
                            </svg>
                        </a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                               aria-label="Open user menu">
                                <span className="avatar avatar-sm"
                                      style={{backgroundImage: `${avatar}`}}/>
                                <div className="d-none d-xl-block ps-2">
                                    <div>{props.loginData.length > 0 ? props.loginData[0]?.name : "User"}</div>
                                    <div className="mt-1 small text-muted">{username}</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <Link to="#" onClick={() => signOut()} className="dropdown-item">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="navbar-expand-md">
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                           viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                           strokeLinecap="round"
                                           strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline
                                          points="5 12 3 12 12 3 21 12 19 12"/><path
                                          d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/><path
                                          d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/></svg>
                                    </span>
                                        <span className="nav-link-title"> Home </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/items">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-list">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l11 0" />
                                            <path d="M9 12l11 0" />
                                            <path d="M9 18l11 0" />
                                            <path d="M5 6l0 .01" />
                                            <path d="M5 12l0 .01" />
                                            <path d="M5 18l0 .01" />
                                        </svg>
                                        <span className="nav-link-title"> Auction Items </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/payments">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-currency-naira">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M7 18v-10.948a1.05 1.05 0 0 1 1.968 -.51l6.064 10.916a1.05 1.05 0 0 0 1.968 -.51v-10.948" />
                                            <path d="M5 10h14" />
                                            <path d="M5 14h14" />
                                        </svg>
                                        <span className="nav-link-title"> My Request(s) </span>
                                    </Link>
                                </li>
                                {
                                    IsAdmin === 1 ? <li className="nav-item">
                                        <Link className="nav-link" to="/approve-application">
                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-checklist"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" /><path d="M14 19l2 2l4 -4" /><path d="M9 8h4" /><path d="M9 12h2" /></svg>
                                            <span className="nav-link-title"> Approve Applications </span>
                                        </Link>
                                    </li> : <></>
                                }
                                {
                                    IsAdmin === 1 ? <li className="nav-item dropdown ">
                                        <a className="nav-link dropdown-toggle" href="#navbar-base"
                                           data-bs-toggle="dropdown"
                                           data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                               {
                                                   MenuIcon("User")
                                               }
                                            </span>
                                            <span className="nav-link-title">Administrator</span>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="dropdown-menu-columns">
                                                <div className="dropdown-menu-column">
                                                    <div className="dropend">
                                                        <a className="dropdown-item dropdown-toggle" href="#"
                                                           data-bs-toggle="dropdown"
                                                           data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                            Items
                                                        </a>
                                                        <div className="dropdown-menu">
                                                            <Link onClick={() => {
                                                                dismissClick()
                                                            }} className="dropdown-item" id="elementId"
                                                                  to="/manage-items">
                                                                Manage Items
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="dropend">
                                                        <a className="dropdown-item dropdown-toggle" href="#"
                                                           data-bs-toggle="dropdown"
                                                           data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                            Request
                                                        </a>
                                                        <div className="dropdown-menu">
                                                            <Link onClick={() => {
                                                                dismissClick()
                                                            }} className="dropdown-item" id="elementId"
                                                                  to="/payment-report-all">
                                                                All Request
                                                            </Link>
                                                            <Link onClick={() => {
                                                                dismissClick()
                                                            }} className="dropdown-item" id="elementId"
                                                                  to="/paid-not-collected">
                                                                Paid Not Collected
                                                            </Link>
                                                            <Link onClick={() => {
                                                                dismissClick()
                                                            }} className="dropdown-item" id="elementId"
                                                                  to="/paid-and-collected">
                                                                Paid And Collected
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li> : <></>
                                }

                            </ul>
                            <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
                                <form action="." method="get">
                                    <div className="input-icon">
                                        <span className="input-icon-addon">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                               height="24"
                                               viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                               strokeLinecap="round"
                                               strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                            fill="none"/><circle cx="10" cy="10" r="7"/><line
                                              x1="21" y1="21" x2="15" y2="15"/></svg>
                                        </span>
                                        {/*<input type="text" className="form-control" placeholder="Search…" aria-label="Search in website"/>*/}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        permissionData: state.permissionData,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(Header);
