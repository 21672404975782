import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import {currencyConverter, formatDate, formatDateAndTime} from "../../resources/constants";
import {Link} from "react-router-dom";

function Items(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "Title", "Price", "Quantity", "StartDate", "EndDate", "Status", "Action"];
    const [formData, setFormData] = useState({
        ItemID: "",
        Title: "",
        Description: "",
        Price: "",
        Quantity: "",
        StartDate: "",
        EndDate: "",
        Status: "",
        ImagePath: "",
        ImageName: "",
        ImageDisplay: "",
        InsertedBy: `${props.loginData[0]?.staff_id}`,
    })
    const [imageList, setImageList] = useState([]);

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}auction/display-auction-items`)
            .then((result) => {
                if (result.data.data.length > 0) {
                    setItemsList(result.data.data);
                    setImageList(result.data.image);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Auction Item(s)
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Auction Item(s)</h3>
                                                <div className="row row-cards">
                                                    {
                                                        itemsList.length > 0 ? itemsList.map((item, index)=>{
                                                            let image_to_display = '';
                                                            let distinctImage =  imageList.filter(e=>e.ItemID.toString() === item.ItemID.toString());
                                                            if(distinctImage.length > 0 ){
                                                                image_to_display = distinctImage[0].ImagePath
                                                            }
                                                            return(
                                                                <div key={index} className="col-sm-6 col-lg-4">
                                                                    <div className="card card-sm">
                                                                        <Link to={`/item/details/${btoa(item.ItemID)}`} className="d-block"><img src={`${serverLink}public/uploads/auction/${image_to_display}`} className="card-img-top"/></Link>
                                                                        <div className="card-body">
                                                                            <div className="">
                                                                                <div><Link to={`/item/details/${btoa(item.ItemID)}`}><h3 className="text-primary font-weight-bold">{item.Title}</h3></Link></div>
                                                                                <hr style={{margin: '10px'}}/>
                                                                                <div className="d-flex  justify-content-between align-content-between">
                                                                                    <div>Duration: </div>
                                                                                    <div className="text-secondary">{formatDateAndTime(item.StartDate, 'date')} - {formatDateAndTime(item.EndDate, 'date')}</div>
                                                                                </div>
                                                                                <div className="d-flex  justify-content-between align-content-between">
                                                                                    <div>Price: </div>
                                                                                    <div className="text-secondary">{currencyConverter(item.Price)}</div>
                                                                                </div>
                                                                                <div className="d-flex  justify-content-between align-content-between">
                                                                                    <div>Quantity: </div>
                                                                                    <div className="text-secondary">{item.Quantity} available</div>
                                                                                </div>
                                                                                {/*<div className="ms-auto">*/}
                                                                                {/*    <a href="#" className="text-secondary">*/}
                                                                                {/*        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path></svg>*/}
                                                                                {/*        467*/}
                                                                                {/*    </a>*/}
                                                                                {/*    <a href="#" className="ms-3 text-secondary">*/}
                                                                                {/*        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path></svg>*/}
                                                                                {/*        67*/}
                                                                                {/*    </a>*/}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : <div className="col-md-12 text-center alert alert-info alert-important">
                                                            <h1 className="text-center">No Available Item</h1>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<ManageItemsForm*/}
                {/*    formData={formData}*/}
                {/*    onEdit={onEdit}*/}
                {/*    onSubmit={onSubmit}*/}
                {/*    IsFormLoading={IsFormLoading}*/}
                {/*    descriptionHandler={descriptionHandler}*/}
                {/*    onMultipleImageChange={onMultipleImageChange}*/}
                {/*    imageList={imageList}*/}
                {/*    setImageList={setImageList}*/}
                {/*/>*/}
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Items);
