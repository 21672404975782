import React, { useState } from 'react';
import './ChatInput.css'; // Import your CSS file

const ChatInput = ({ onSendMessage }) => {
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() !== '') {
            onSendMessage(message);
            setMessage('');
        }
    };

    return (
        <form className="chat-input-form" onSubmit={handleSubmit}>
            <div className="input-container">
        <textarea
            placeholder="Type your message..."
            value={message}
            onChange={handleChange}
        />
                <button type="submit">Send</button>
            </div>
        </form>
    );
};

export default ChatInput;
